@import "../../../../assets/styles/variables";

.successPageWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-family: $form-font;
  margin-top: 10rem;

  .completedIcon {
    width: 10rem;
    height: 10rem;
    display: flex;
    border-radius: 50%;
    background-color:  #80bc47;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .checked {
      background-image: url("../../../../assets/images/check_icon.png");
      background-size: 3.5rem 2.6rem;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }

  .successPageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    font-family: Ubuntu;

    .successTitle {
      font-size: $l-font-size;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .successSubTitle {
      font-size: $base-m-font-size;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 2rem;
      }
    }

    .buttonWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: $breakpoint-xsmall) {
  .successPageWrapper {
    margin-top: 0;
    padding: 1rem;
  }
}

