.select {
  position: relative;

  &.calendar-select {
    &:before {
      display: none;
    }

    width: 30rem;

    .basic-single {
      .select__menu {
        z-index: 10000
      }
    }
  }
}
